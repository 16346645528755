import { API, graphqlOperation } from "aws-amplify";
import {
  listEntreeDishs,
  listRiceDishs,
  listBreadDishs,
  listBeverages,
} from "../graphql/queries";
import { DishList } from '../models/model';

const api = {
  _unwrap(result: any, key: string) {
    const data = result.data[key];
    return data && data.items ? data.items : data; // handle arrays and objects
  },

  _subscribe(subscription: any, fn: any) {
    const response = API.graphql(graphqlOperation(subscription))
    return response.subscribe({
      next: fn,
    });
  },

  listEntreeDishes() {
    return API.graphql(graphqlOperation(listEntreeDishs)).then(
      (result: any) => this._unwrap(result, "listEntreeDishs")
    )
  },

  listRiceDishes() {
    return API.graphql(graphqlOperation(listRiceDishs)).then(
      (result: any) => this._unwrap(result, "listRiceDishs")
    )
  },

  listBreadDishes() {
    return API.graphql(graphqlOperation(listBreadDishs)).then(
      (result: any) => this._unwrap(result, "listBreadDishs")
    )
  },

  listBeverages() {
    return API.graphql(graphqlOperation(listBeverages)).then(
      (result: any) => this._unwrap(result, "listBeverages")
    )
  },

  getRandomDishForEachCateory(dishArray: DishList[]) {
    let dishArrayofEachCategory: any = [];
    dishArray.forEach((category: any) => {
      let index = Math.floor(Math.random() * category.length);
      dishArrayofEachCategory.push(category[index]);
    });
    return dishArrayofEachCategory;
  },

  getLists() {
    const lists: DishList[] = [];
    lists.push(this.listEntreeDishes());
    lists.push(this.listRiceDishes());
    lists.push(this.listBeverages());
    lists.push(this.listBreadDishes());
    return Promise.all(lists).then(
      (results: DishList[]) => results
    );
  },
};

export default api;
