import React, { useEffect, useState } from "react";
import cheflogo from "./cheflogo.png";
import "./App.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import CardGrid from "./components/cardGrid";
import Api from "./services/api.service";
import spinner from "./Spinner.svg";
import reload from "./reload.svg";
Amplify.configure(aws_exports);

function App({ isPassedToWithAuthenticator, signOut, user }) {
  if (!isPassedToWithAuthenticator) {
    throw new Error(`isPassedToWithAuthenticator was not provided`);
  }
  
  const [showCards, setShowCards] = useState(false);
  const [dishes, setDishes] = useState([]);
  const [dishList, setDishList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function showDishes(booleanValue) {
    setShowCards(booleanValue);
    setDishList(Api.getRandomDishForEachCateory(dishes));
  }

  // Load dishes.
  useEffect(() => {
    try {
      getDishes();
    } catch (error) {
      console.log("error fetching the dishes", error);
      setIsLoading(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

   function getDishes() {
    Api.getLists().then((list) => setDishes(list)).then(() => {
    const randomList = Api.getRandomDishForEachCateory(dishes);
    setDishList(randomList);
    setIsLoading(false);
    });
  }

  return (
    <div>
      <>
      <h1>Hello {user.username}</h1>
      <button onClick={signOut}>Sign out</button>
    </>
      {!isLoading ? (
        <div className="App">
          <h1>What should I eat ?</h1>
          {showCards ? (
            <div>
              <CardGrid dishes={dishList} />
            </div>
          ) : (
            <div>
              <button className="Logo-button">
                <img className="App-logo"
                  src={cheflogo}
                  alt="cheflogo"
                  onClick={() => showDishes(true)}
                />
              </button>
              <h2 className="App-text">click our chef to find out !</h2>
            </div>
          )}
          {showCards && (
            <div>
              <br/>
              <button className="Reload-button" onClick={() => showDishes(false)}>
                <img width={64} height={64} src={reload} alt="reload options" />
              </button>
            </div>
          )}
        </div>
      ) : (
        <center>
          <img src={spinner} alt="loading ..."></img>
        </center>
      )}
    </div>
  );
}

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}

export default withAuthenticator(App);
