import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Dish } from "../models/model";
import RecipeCard from "./card";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1,
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)",
    },
  })
);

function renderRow(tile: Dish, index: number) {
  return (
    <Grid item key={index}>
      <RecipeCard {...tile} />
    </Grid>
  );
}

function renderGrid(dishes: Dish[]) {
  return dishes.map(renderRow);
}

export default function CardGrid({ dishes }: { dishes: Dish[] }) {
  const classes = useStyles();
  return (
    <div>
      <h2>Your Personalized Options</h2>
      <div className={classes.root}>
        <Grid container direction="column" spacing={3} alignItems="center" justify="space-evenly">
          {renderGrid(dishes.slice(0, dishes.length / 2))}
        </Grid>
        <Grid container direction="column" spacing={3} alignItems="center" justify="space-evenly">
          {renderGrid(dishes.slice(dishes.length / 2, dishes.length))}
        </Grid>
      </div>
    </div>
  );
}
