/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEntreeDish = /* GraphQL */ `
  query GetEntreeDish($id: ID!) {
    getEntreeDish(id: $id) {
      id
      name
      image
      description
    }
  }
`;
export const listEntreeDishs = /* GraphQL */ `
  query ListEntreeDishs(
    $filter: ModelEntreeDishFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntreeDishs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        description
      }
      nextToken
    }
  }
`;
export const getRiceDish = /* GraphQL */ `
  query GetRiceDish($id: ID!) {
    getRiceDish(id: $id) {
      id
      name
      image
      description
    }
  }
`;
export const listRiceDishs = /* GraphQL */ `
  query ListRiceDishs(
    $filter: ModelRiceDishFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRiceDishs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        description
      }
      nextToken
    }
  }
`;
export const getBreadDish = /* GraphQL */ `
  query GetBreadDish($id: ID!) {
    getBreadDish(id: $id) {
      id
      name
      image
      description
    }
  }
`;
export const listBreadDishs = /* GraphQL */ `
  query ListBreadDishs(
    $filter: ModelBreadDishFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBreadDishs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        description
      }
      nextToken
    }
  }
`;
export const getBeverage = /* GraphQL */ `
  query GetBeverage($id: ID!) {
    getBeverage(id: $id) {
      id
      name
      image
      description
    }
  }
`;
export const listBeverages = /* GraphQL */ `
  query ListBeverages(
    $filter: ModelBeverageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBeverages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        description
      }
      nextToken
    }
  }
`;
